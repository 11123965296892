<template>
  <div class="intro-container" id="mainPage">
    <div class="part">
      <h1 class="header">اِکس پلیمر</h1>
      <div class="text">پلیمر سبز، زمین پاک، آینده پایدار</div>
    </div>
    <div class="part image">
      <img src="/img/homebanner.webp" alt="polymer photo" />
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.intro-container {
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 20px;
  height: 280px;
  width: 1240px;
  .part {
    &:first-child {
      display: flex;
      flex-direction: column;
    }
    &:last-child {
      margin-top: 1.6rem;
    }
    &.image {
      position: absolute;
      left: 0;
      z-index: -1;
      img {
        width: 950px;
      }
    }
    /* img {
      height: 440px;
    } */
    .header {
      font-size: 65px;
      font-weight: bold;
      color: #1c66e1;
    }
    .text {
      font-size: 26px;
    }
  }
}
@media only screen and (max-width: 1050px) {
  .intro-container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 260px;
    width: 90%;
    margin: 0 5%;
    .part {
      img {
        height: 350px;
      }
      .header {
        font-size: 60px;
      }
      .text {
        font-size: 30px;
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .intro-container {
    height: 230px;

    .part {
      img {
        height: 300px;
      }
      .header {
        font-size: 50px;
      }
      .text {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .intro-container {
    height: 320px;
    flex-direction: column;
    text-align: center;
    .part {
      &.image {
        position: absolute;
        left: 0;
        bottom: 15px;
        z-index: -1;
        img {
          width: 100%;
          height: 150px;
        }
      }
      .header {
        font-size: 45px;
        padding-bottom: 15px;
      }
      .text {
        font-size: 25px;
      }
    }
  }
}
</style>
