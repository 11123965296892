<template>
  <nav>
    <div class="leftside">
      <div class="call"><button @click="call">تماس بگیرید</button></div>
      <div class="icons">
        <div class="menu" @click="setMenu">
          <MenuSvg />
          <div :class="showMenu ? 'popup show' : 'popup'">
            <a class="link" @click="scrollToTop">صفحه اصلی</a>
            <a class="link" href="#services">بازار و خدمات</a>
            <a class="link" href="#news">اخبار و مقالات</a>
            <a class="link" href="#aboutus">درباره ما</a>
            <a class="link" href="#cantactus">تماس با ما</a>
          </div>
        </div>
        <div class="language">
          <LanguageSvg />
          <div class="popup">
            <div>
              <span
                ><img src="/img/englishflag.webp" alt="English flag"
              /></span>
              <span> English </span>
            </div>
            <div>
              <span><img src="/img/iranflag.webp" alt="iran flag" /></span>
              <span> فارسی </span>
            </div>
            <div>
              <span
                ><img src="/img/arabicflag.webp" alt="Saudi Arabia flag"
              /></span>
              <span> العربی </span>
            </div>
          </div>
        </div>
        <!-- <div @click="phone" class="message"><MessageSvg /></div> -->
        <div class="user"><AddUserSvg /></div>
      </div>
    </div>
    <div class="middleside">
      <div class="links">
        <a class="link" @click="scrollToTop">صفحه اصلی</a>
        <a class="link" href="#services">بازار و خدمات</a>
        <a class="link" href="#news">اخبار و مقالات</a>
        <a class="link" href="#aboutus">درباره ما</a>
        <a class="link" href="#cantactus">تماس با ما</a>
      </div>
    </div>

    <div class="rightside">
      <span class="logo"
        ><img src="/img/ex.webp" alt="expolymer logo" @click="scrollToTop"
      /></span>
    </div>
  </nav>
</template>
<script setup>
import LanguageSvg from "./svgs/LanguageSvg.vue";
import AddUserSvg from "./svgs/AddUserSvg.vue";
import MenuSvg from "./svgs/MenuSvg.vue";
import { ref } from "vue";
// import MessageSvg from "./svgs/MessageSvg.vue";
const showMenu = ref(false);
import router from "@/router";
const setMenu = () => {
  showMenu.value = !showMenu.value;
};
// const phone = () => {
//   window.open("tel:09121002186");
// };
const call = () => {
  // window.location("#cantactus");
  window.location.hash = "cantactus";
  // window.open("tel:02182804095");
};
const scrollToTop = () => {
  router.push("/");
  window.scrollTo(0, 0);
};
</script>
<style lang="scss" scoped>
nav {
  z-index: 1000;
  height: 75px;
  width: 1240px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #0f2851;
  border-radius: 18px;
  padding: 10px;
  /* border-top: 1px solid #eff2f5;
  border-bottom: 1px solid #eff2f5; */
  position: sticky !important;
  top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  a {
    text-decoration: none;
  }
  .middleside {
    .links {
      display: flex;
      align-items: center;
      .link {
        color: white;
        padding: 13px 10px;
        font-size: 15px;
        &:hover {
          color: #1c66e1;
          cursor: pointer;
          text-decoration: solid underline #1c66e1 1px;
          text-underline-offset: 20px;
          transition: 0.2s;
        }
      }
    }
  }
  .rightside {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    .logo {
      position: absolute;
      right: -45px;
      background-color: white;
      padding: 8px 10px;
      border-radius: 8px;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
      -moz-box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
      box-shadow: 0px 0px 13px -5px rgb(0, 0, 0);
      img {
        height: 32px;
      }
    }
    .links {
      display: flex;
      align-items: center;
      margin-right: 3rem;
      .link {
        position: relative;
        padding: 24px 0;
        font-weight: bold;
        font-size: 15px;
        &:hover {
          color: #3861fb;
          cursor: pointer;
        }
        .hidden-links {
          display: none;
        }
      }
      .polymers {
        &:hover {
          .hidden-links {
            padding: 1rem 0;
            z-index: 10;
            display: flex;
            height: 500px;
            width: 350px;
            top: 60px;
            left: -200px;
            position: absolute;
            background-color: white;
            border-radius: 8px;
            -webkit-box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
            -moz-box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
            box-shadow: 0px 0px 23px -13px rgba(0, 0, 0, 0.64);
            justify-content: space-between;
            .right {
              padding: 0 2rem;
            }
            .left {
              border-right: #eff2f5 1px solid;
              padding: 0 2rem;
            }
            .header {
              color: #a6b0c3;
            }
            .sublinks {
              margin-top: 1rem;
              div {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  .leftside {
    display: flex;
    direction: ltr;
    height: 100%;
    /* align-items: flex-start; */
    flex: 1;
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      .menu {
        display: none;
      }

      .user {
        padding-left: 10px;
        svg {
          width: 25px;
          height: 25px;
        }
      }
      svg {
        fill: white;
        width: 20px;
        height: 20px;
      }
      .message {
        padding-right: 10px;
        transform: rotateY(180deg);
        svg {
          fill: transparent;
          width: 25px;
          height: 25px;
        }
      }
      .language {
        position: relative;
        cursor: pointer;
        .popup {
          display: none;
          position: absolute;
          right: -30px;
          top: 20px;
          font-size: 13px;
          background-color: white;
          padding: 10px;
          border-radius: 8px;
          text-align: center;
          -webkit-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
          div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            &:hover {
              color: #3861fb;
            }
            span {
              &:first-child {
                padding-left: 7px;
              }
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
        svg {
          width: 23px;
          height: 23px;
        }
        &:hover {
          .popup {
            display: flex;
            flex-direction: column;
            div {
              padding-bottom: 5px;
              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
    .call {
      margin-right: 10px;
      button {
        border: none;
        background-color: #2068e1;
        color: white;
        border-radius: 8px;
        height: 100%;
        padding: 0 20px;
        &:hover {
          transform: scale(1.1);
          transition: 0.3s;
        }
      }
    }
    .menu {
      svg {
        height: 50px;
        width: 40px;
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  nav {
    width: 90%;
    height: 70px;
  }
}
@media only screen and (max-width: 770px) {
  nav {
    width: 90%;
    height: 70px;
    .middleside {
      flex: 3;
      .links {
        .link {
          font-size: 13px;
        }
      }
    }
    .rightside {
      .logo {
        right: -45px;
        img {
          height: 25px;
        }
      }
    }
    .leftside {
      .icons {
        .user {
          padding-left: 10px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .language {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .call {
        margin-right: 10px;
        button {
          font-size: 12px;
          width: 100px;
          padding: 0 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  nav {
    width: 95%;
    height: 60px;
    .middleside {
      .links {
        display: none;
      }
    }
    .rightside {
      .logo {
        right: 0px;
        img {
          height: 25px;
        }
      }
    }
    .leftside {
      .icons {
        .menu {
          display: flex;
          margin-right: 10px;
          position: relative;
          .popup {
            display: none;
            &.show {
              display: flex;
              width: 100px;
              flex-direction: column;
              position: absolute;
              background-color: white;
              padding: 5px;
              border-radius: 10px;
              top: 30px;
              left: -10px;
              text-align: center;
              .link {
                margin: 0 9px;
                border-bottom: #0f2851 solid 1px;
                font-size: 13px;
                padding: 5px 0;

                &:last-child {
                  border-bottom: none;
                  padding-bottom: 3px;
                }
              }
              -webkit-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
              box-shadow: 0px 0px 26px -6px rgba(0, 0, 0, 0.75);
            }
          }
          svg {
            width: 25px;
            height: 25px;
          }
        }
        .user {
          padding-left: 10px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .language {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .call {
        display: none;
      }
    }
  }
}
</style>
