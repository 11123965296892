<template>
  <div class="page-container">
    <NavbarComponent />
    <ContactUsTools />
    <div class="main-container">
      <div class="container">
        <div class="topbanner">
          <div class="back-img"><img src="../assets/PolymerPage.webp" /></div>
          <div class="address">صفحه اصلی/محصولات/مواد پلیمری/پلی پروپیان</div>
          <h1 class="title">پلی پروپیان</h1>
          <div class="buttons">
            <button>دانلود دیتاشیت</button>
            <button>آرشیو قیمت</button>
          </div>
          <div class="tools">
            <div>افزودن به علاقه مندی</div>
            <div>اشتراک گذاری</div>
            <div>نمودار قیمت</div>
          </div>
        </div>
        <div class="nav">
          <div class="links">
            <div
              :class="tabActive === 0 ? 'link active' : 'link'"
              @click="setTapActive(0)"
            >
              مشخصات عمومی
            </div>
            <div
              :class="tabActive === 1 ? 'link active' : 'link'"
              @click="setTapActive(1)"
            >
              خواص فیزیکی
            </div>
            <div
              :class="tabActive === 2 ? 'link active' : 'link'"
              @click="setTapActive(2)"
            >
              خواص حرارتی
            </div>
            <div
              :class="tabActive === 3 ? 'link active' : 'link'"
              @click="setTapActive(3)"
            >
              خواص نوری
            </div>
            <div
              :class="tabActive === 4 ? 'link active' : 'link'"
              @click="setTapActive(4)"
            >
              موارد کاربردی
            </div>
            <div
              :class="tabActive === 5 ? 'link active' : 'link'"
              @click="setTapActive(5)"
            >
              توضیحات تکمیلی
            </div>
          </div>
        </div>
        <div class="part" id="public">
          <h2 :class="tabActive === 0 ? 'header active' : 'header'">
            مشخصات عمومی
          </h2>
          <div class="body">
            <div class="row">
              <div class="title col-3">نام گرید</div>
              <div class="text col">SE-3000</div>
            </div>
            <div class="row">
              <div class="title col-3">تولید کننده</div>
              <div class="text col">بانیار پلیمر</div>
            </div>
            <div class="row">
              <div class="title col-3">دسته ها</div>
              <div class="text col">
                <span class="tag">#پلیمر</span>
                <span class="tag">#ترمو</span>
                <span class="tag">#پلی</span>
              </div>
            </div>
            <div class="row">
              <div class="title col-3">کاربرد ها</div>
              <div class="text col">
                <span class="tag">#پلیمر</span>
                <span class="tag">#ترمو</span>
                <span class="tag">#پلی</span>
              </div>
            </div>
            <div class="row">
              <div class="title col-3">افزوده شده با</div>
              <div class="text col">تاخیر دهنده شعله</div>
            </div>
            <div class="row">
              <div class="title col-3">ویژگی ها</div>
              <div class="text col">خود خاموش شونده</div>
            </div>
          </div>
        </div>
        <div class="part" id="physical">
          <h2 :class="tabActive === 1 ? 'header active' : 'header'">
            خواص فیزیکی
          </h2>
          <div class="body">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <th>مقدار</th>
                  <th>واحد</th>
                  <th>شرایط آزمون</th>
                  <th>روش آزمون</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Bead Size</th>
                  <td>1-07</td>
                  <td>mm</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>BlowingAgent</th>
                  <td>7-4</td>
                  <td>%</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Density</th>
                  <td>0.013</td>
                  <td>g/cm³</td>
                  <td>first Expansion</td>
                  <td></td>
                </tr>
                <tr>
                  <th>Density</th>
                  <td>0.009</td>
                  <td>g/cm³</td>
                  <td>second Expansion</td>
                  <td></td>
                </tr>
                <tr>
                  <th>K-Value</th>
                  <td>57-52</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Pentane Content</th>
                  <td>5.2</td>
                  <td>%</td>
                  <td>{{ "5.2<" }}</td>
                  <td></td>
                </tr>
                <tr>
                  <th>Specific Gravity</th>
                  <td>1.03</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Styrene Residual Monomer</th>
                  <td>2000</td>
                  <td>ppm</td>
                  <td>2000></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="part" id="tempture">
          <h2 :class="tabActive === 2 ? 'header active' : 'header'">
            خواص حرارتی
          </h2>
          <div class="body">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <th>مقدار</th>
                  <th>واحد</th>
                  <th>شرایط آزمون</th>
                  <th>روش آزمون</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Limiting Oxygen Index (LOI)</th>
                  <td>30 - 25</td>
                  <td>%</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Melting Point</th>
                  <td>175 - 165</td>
                  <td>C</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Oven Aging</th>
                  <td>6 - 4</td>
                  <td>h</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="part" id="light">
          <h2 :class="tabActive === 3 ? 'header active' : 'header'">
            خواص نوری
          </h2>
          <div class="body">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <th>مقدار</th>
                  <th>واحد</th>
                  <th>شرایط آزمون</th>
                  <th>روش آزمون</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Limiting Oxygen Index (LOI)</th>
                  <td>30 - 25</td>
                  <td>%</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Melting Point</th>
                  <td>175 - 165</td>
                  <td>C</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Oven Aging</th>
                  <td>6 - 4</td>
                  <td>h</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="part description" id="description">
          <div :class="tabActive === 5 ? 'header active' : 'header'">
            توضیحات تکمیلی
          </div>
          <div class="text">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
            استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی
            در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را
            می طلبد
          </div>
        </div>
        <div class="others">
          <div class="header"></div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>
<script setup>
import ContactUsTools from "@/components/ContactUsTools.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
console.log(route.params);
const tabActive = ref(0);
const setTapActive = (e) => {
  tabActive.value = e;
  if (e === 0) {
    const section = document.getElementById("public");
    const offset = 100; // Scroll slightly below the target
    const top = section.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
  if (e === 1) {
    const section = document.getElementById("physical");
    const offset = 100; // Scroll slightly below the target
    const top = section.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
  if (e === 2) {
    const section = document.getElementById("tempture");
    const offset = 100; // Scroll slightly below the target
    const top = section.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
  if (e === 3) {
    const section = document.getElementById("light");
    const offset = 100; // Scroll slightly below the target
    const top = section.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
  if (e === 5) {
    const section = document.getElementById("description");
    const offset = 100; // Scroll slightly below the target
    const top = section.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
};
</script>
<style lang="scss" scoped>
.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: transparent;
  /* overflow: hidden; */
  padding: 0 15%;
  width: 100%;
  .container {
    .topbanner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      .back-img {
        position: absolute;
        z-index: -1;
        top: -50px;
        img {
          height: 350px;
          width: 900px;
        }
      }
      .address {
        color: gray;
      }
      .title {
        font-size: 50px;
        color: #2068e1;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .buttons {
        display: flex;
        gap: 20px;
        :first-child {
          background-color: #0f2851;
          color: white;
          border: none;
          padding: 5px 0;
          border-radius: 6px;
          width: 140px;
        }
        :last-child {
          background-color: #2068e1;
          border: 1px solid white;
          color: white;

          padding: 5px 0px;
          border-radius: 6px;
          width: 140px;
        }
      }
      .tools {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid gray;
        border-radius: 8px;
        padding: 7px;
        color: gray;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 60px;
        div {
          border-left: 2px solid gray;
          padding: 0 10px;
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nav {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: white;
        padding: 10px 40px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        .link {
          color: gray;
          font-size: 19px;
          cursor: pointer;
          &.active {
            color: #2068e1;
            font-weight: bold;
          }
        }
      }
    }
    .part {
      padding: 0 20px;
      &.description {
        font-size: 13px;
        /* font-weight: bold; */
        margin-bottom: 12rem;
        .text {
          margin-top: 10px;
        }
      }
      .header {
        border-bottom: 4px solid #d7d7d7;
        padding-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
        color: gray;
        margin-top: 25px;
        position: relative;
        padding-right: 10px;
        &.active {
          &::after {
            content: "";
            position: absolute;
            right: 10px;
            bottom: -4px; /* Positioning it right below the original border */
            width: 120px;
            height: 4px;
            background-color: #2068e1;
            /* transform: translateX(-50%); Center the border */
          }
        }
      }
      .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        .row {
          border-bottom: 1px solid #d7d7d7;
          padding: 10px 0;
          font-size: 14px;
          .title {
            font-weight: bold;
          }
          .text {
            .tag {
              background-color: #d7d7d7;
              margin-left: 10px;
              padding: 5px;
              border-radius: 6px;
              font-size: 13px;
            }
          }
        }
        table {
          margin-top: 10px;
          font-size: 14px !important;
          tr {
            border-bottom: 1px solid #d7d7d7;
            td {
              padding: 8px;
            }
            th {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .main-container {
    padding: 0 0;
    .container {
      .nav {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .links {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;

          .link {
            color: gray;
            font-size: 11px;
            cursor: pointer;
            text-align: center;
            &.active {
              color: #2068e1;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
