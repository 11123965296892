<template>
  <div id="services">
    <div class="category-container mobile">
      <div class="header">
        <h2>
          <span class="bold py-1">بازار و خدمات </span>
          <span>اِکس پلیمر </span>
        </h2>
        <div class="text">
          برای مشاهده محتویات هر یک از خدمات زیر، آن را انتخاب کنید.
        </div>
      </div>
      <div class="pictures">
        <div class="arrow-right" @click="prevPicture"><ArrowRightSvg /></div>
        <div class="picture">
          <div class="img-container">
            <img
              :src="items[currentIndex].imgSrc"
              :alt="items[currentIndex].name"
            />
          </div>
          <div class="text" @click="goLocation(items[currentIndex].url)">
            <!-- <span class="pe-2" v-if="currentIndex === 0"><KeepingSvg /></span>
            <span class="pe-2" v-if="currentIndex === 1"><CleanSvg /></span>
            <span class="pe-2" v-if="currentIndex === 2"><ParakSvg /></span> -->
            <span>{{ items[currentIndex].name }}</span>
          </div>
        </div>
        <div class="arrow-left" @click="nextPicture"><ArrowRightSvg /></div>
      </div>
    </div>
    <div class="category-container">
      <div class="logo"><ServicesSvg /></div>
      <div class="header">
        <h2>
          <span class="bold py-1">بازار و خدمات </span>
          <span> اِکس پلیمر </span>
        </h2>
        <div class="text">
          برای مشاهده محتویات هر یک از خدمات زیر، آن را انتخاب کنید.
        </div>
      </div>
      <div class="pictures">
        <div class="arrow-right" @click="prevPicturePC"><ArrowRightSvg /></div>
        <a class="picture" target="_blank" :href="items[activePages[0]].url">
          <div class="img-container">
            <img
              :src="items[activePages[0]].imgSrc"
              :alt="items[activePages[0]].name"
            />
          </div>
          <div class="text" @click="goLocation(items[activePages[0]].url)">
            <!-- <span class="pe-2"><KeepingSvg /></span> -->
            <span>{{ items[activePages[0]].name }}</span>
          </div>
        </a>
        <a class="picture" target="_blank" :href="items[activePages[1]].url">
          <div class="img-container">
            <img
              :src="items[activePages[1]].imgSrc"
              :alt="items[activePages[1]].name"
            />
          </div>
          <div class="text" @click="goLocation(items[activePages[1]].url)">
            <!-- <span class="pe-2"><KeepingSvg /></span> -->
            <span>{{ items[activePages[1]].name }}</span>
          </div>
        </a>
        <a class="picture" target="_blank" :href="items[activePages[2]].url">
          <div class="img-container">
            <img
              :src="items[activePages[2]].imgSrc"
              :alt="items[activePages[2]].name"
            />
          </div>
          <div class="text" @click="goLocation(items[activePages[2]].url)">
            <!-- <span class="pe-2"><KeepingSvg /></span> -->
            <span>{{ items[activePages[2]].name }}</span>
          </div>
        </a>
        <div class="arrow-left" @click="nextPicturePC"><ArrowRightSvg /></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import ServicesSvg from "@/components/svgs/ServicesSvg.vue";

import ArrowRightSvg from "./svgs/ArrowRightSvg.vue";
const goLocation = (e) => {
  // window.location = e;
  window.open(e, "_blank");
};
const items = [
  {
    name: "مشاوره خرید مواد",
    url: "https://expolymer.ir/blog/materials-consultation/",
    text: `در اکسپلیمر ما این امکان را به صورت رایگان فراهم کردیم که به صورت شفاف و روزانه، قیمت‌های مواد پلیمری را دریافت کنید و بهترین تصمیمات خرید را بگیرید. ادامه مطلب ...`,
    imgSrc: "/img/bazar/contactus.webp",
  },
  {
    name: "کامپاند و گرانول",
    url: "https://expolymer.ir/blog/granulate/",
    text: `تولید کامپاند و مستربچ با کیفیت عالی در اکسپلیمر. سفارشی‌سازی مواد پلیمری بر اساس نیاز شما، تولید کامپاند و گرانول با بالاترین کیفیت و کمترین قیمت بازار. ادامه مطلب....`,
    imgSrc: "/img/bazar/producecompound.webp",
  },
  {
    name: "افزودنی ها و مستربچ",
    url: "https://expolymer.ir/blog/polymer-additives/",
    text: `اگر به دنبال بهبود کیفیت محصولات پلیمری خود هستید یا نیاز به مواد افزودنی و مستربچ با عملکرد بالا دارید، ما در اکسپلیمر بهترین راه‌حل‌ها را به شما ارائه می‌دهیم. ادامه مطلب ...`,
    imgSrc: "/img/bazar/masterpech.webp",
  },
  {
    name: "دستگاه های صنعتی",
    url: "https://expolymer.ir/blog/industrial-devices/",
    text: `مشاوره رایگان، فروش و راه‌اندازی دستگاه‌های پلیمری با اکسپلیمر. از خرید تا تعمیرات، همراه شما هستیم. برای بهترین تجهیزات و خدمات تخصصی با ما در ارتباط باشید. ادامه مطلب...`,
    imgSrc: "/img/bazar/mashine.webp",
  },
  {
    name: "پرک و آسیاب",
    url: "https://expolymer.ir/blog/feather/",
    text: `با خدمات پرک و آسیاب، بازیافت پلاستیک و کاهش آلودگی را ممکن می‌کنیم. اکسپلیمر با تجهیزات پیشرفته و قیمت رقابتی، ضایعات را به مواد ارزشمند تبدیل می‌کند. ادامه مطلب ...`,
    imgSrc: "/img/bazar/parak.webp",
  },
  {
    name: "محصولات",
    url: "https://expolymer.ir/blog/production/",
    text: `تولید محصولات پلیمری سفارشی با کیفیت عالی در اکسپلیمر. اکسپلیمر با امکانات و تجهیزات پیشرفته، آماده ارائه مشاوره تخصصی مواد، جهت تولید محصولات مورد نیاز شماست. ادامه مطلب... `,
    imgSrc: "/img/bazar/products.webp",
  },
  {
    name: "شست و شوی مواد",
    url: "https://expolymer.ir/blog/washing-materials/",
    text: `شرکت اکسپلیمر با مدیریت مجموعه ای مجهز و پیشرفته، خدمات شستشوی مواد پلیمری را با بالاترین کیفیت ارائه می‌دهد. مواد پلیمری پس از استفاده یا تولید، ادامه مطلب...`,
    imgSrc: "/img/bazar/washing.webp",
  },
  {
    name: "صادرات و واردات",
    url: "https://expolymer.ir/blog/export/",
    text: `اگر به دنبال تامین مواد پلیمری از پتروشیمی‌های ایران برای صادرات به خارج از کشور هستید، یا نیاز به واردات مواد پلیمری خاص از خارج برای صنایع داخلی دارید، ادامه مطلب ...`,
    imgSrc: "/img/bazar/importandexport.webp",
  },
  {
    name: "انبارداری",
    url: "https://expolymer.ir/blog/warehousing/",
    text: `نگهداری، انبارداری و دپو مواد پلیمری، یکی از مراحل حساس در زنجیره تأمین این مواد است. مواد پلیمری، به دلیل ویژگی‌ های خاص خود، نیازمند شرایط نگهداری ادامه مطب...`,
    imgSrc: "/img/bazar/warehouse.webp",
  },
];
// console.log(itemss);
const currentIndex = ref(0);
const currentIndexPC = ref(1);
const activePages = ref([0, 1, 2]);
const nextPicture = () => {
  currentIndex.value = (currentIndex.value + 1) % items.length;
};

const prevPicture = () => {
  currentIndex.value = (currentIndex.value - 1 + items.length) % items.length;
};
const nextPicturePC = () => {
  console.log("next");
  console.log(currentIndexPC.value);
  let page = currentIndexPC.value;
  if (page === 1) {
    currentIndexPC.value = 2;
    activePages.value = [3, 4, 5];
  }
  if (page === 2) {
    currentIndexPC.value = 3;
    activePages.value = [6, 7, 8];
  }
  if (page === 3) {
    currentIndexPC.value = 1;
    activePages.value = [0, 1, 2];
  }
};

const prevPicturePC = () => {
  console.log("perv");
  console.log(currentIndexPC.value);
  let page = currentIndexPC.value;
  if (page === 1) {
    currentIndexPC.value = 3;
    activePages.value = [6, 7, 8];
  }
  if (page === 2) {
    currentIndexPC.value = 1;
    activePages.value = [0, 1, 2];
  }
  if (page === 3) {
    currentIndexPC.value = 2;
    activePages.value = [3, 4, 5];
  }
};
</script>
<style lang="scss" scoped>
.category-container {
  padding-top: 6rem;
  z-index: 10;
  padding-bottom: 5rem;
  display: flex;
  width: 1240px;
  flex-direction: column;
  background-color: white;
  &.mobile {
    display: none;
  }
  .logo {
    animation: 2s infinite alternate ease-in-out upAndDown;
    svg {
      width: 65px;
      fill: #1c66e1;
    }
  }
  .header {
    text-align: right;
    font-size: 28px;
    font-weight: bold;
    .bold {
      color: #1c66e1;
      padding-left: 5px;
    }
    .text {
      font-size: 14px;
      font-weight: normal;
      color: #b4b4b4;
      margin-top: 10px;
    }
  }
  .pictures {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    position: relative;
    .arrow-right {
      position: absolute;
      top: 50%;
      /* transform: translateY(50%); */
      right: -30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      z-index: 10;
      border: 1px solid #1c66e1;
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .arrow-left {
      position: absolute;
      top: 50%;
      left: -30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      border: 1px solid #1c66e1;
      svg {
        transform: rotate(180deg);
        width: 12px;
        height: 12px;
      }
    }
    .picture {
      position: relative;
      width: 350px;
      .img-container {
        border-radius: 18px;
        border: #1c66e1 2px solid;
        padding: 3px;
        img {
          border-radius: 18px;
          width: 100%;
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1c66e1;
        position: absolute;
        bottom: -10px;
        right: -10px;
        width: 158px;
        height: 52px;
        border-radius: 20px 0px 20px 0px;
        z-index: 2;
        &:hover {
          cursor: pointer;
          bottom: 0px;
          right: 0px;
          transition: 0.3s;
        }
        span {
          color: white;
          svg {
            fill: white;
            width: 18px !important;
            height: 18px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .category-container {
    width: 90%;
    margin: 0 5%;
    margin-top: 4rem;
    margin-bottom: 5rem;
    .logo {
      svg {
        width: 60px;
      }
    }
    .header {
      font-size: 26px;
      .text {
        font-size: 13px;
      }
    }
    .pictures {
      margin-top: 2rem;
      .picture {
        width: 250px;

        .text {
          bottom: -10px;
          right: -10px;
          width: 140px;
          height: 45px;
          font-size: 14px;

          span {
            svg {
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .category-container {
    width: 95%;
    margin: 0 2.5%;
    margin-top: 4rem;
    margin-bottom: 5rem;
    .logo {
      svg {
        width: 50px;
      }
    }
    .header {
      font-size: 24px;
      .text {
        font-size: 11px;
      }
    }
    .pictures {
      margin-top: 2rem;
      .picture {
        width: 210px;

        .text {
          bottom: -7px;
          right: -7px;
          width: 120px;
          height: 35px;
          font-size: 11px;
          span {
            svg {
              width: 13px !important;
              height: 13px !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .category-container {
    display: none;
    width: 95%;
    margin: 0 2.5%;
    margin-top: 1rem;
    margin-bottom: 0;
    &.mobile {
      display: flex;

      .arrow-right {
        position: absolute;
        top: 50%;
        /* transform: translateY(50%); */
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        z-index: 10;
        border: 1px solid #1c66e1;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #1c66e1;
        svg {
          transform: rotate(180deg);
          width: 12px;
          height: 12px;
        }
      }
    }
    .logo {
      svg {
        width: 50px;
      }
    }
    .header {
      text-align: center;
      font-size: 24px;
      .text {
        font-size: 11px;
      }
    }
    .pictures {
      margin-top: 2rem;
      position: relative;
      display: flex;
      justify-content: center;
      .picture {
        width: 90%;

        .text {
          bottom: 0px;
          right: 0px;
          border-radius: 0 0 12px 12px;
          height: 35px;
          font-size: 14px;
          width: 100%;
          span {
            svg {
              width: 17px !important;
              height: 17px !important;
            }
          }
        }
      }
    }
  }
}
</style>
