<template>
  <div class="support-container">
    <div class="patterns">
      <img src="/img/supportPattern.webp" alt="background pattern" />
    </div>
    <div class="section">
      <div class="image"><img src="/img/support.webp" alt="support" /></div>
      <div class="paragraph">
        <img src="/img/boxSupport.png" alt="message shape" />
        <div class="text">
          <h2 class="header">
            <span class="bold">مشاوره با</span>
            اِکس پلیمر
          </h2>
          <div class="details">
            برای مشاوره خرید، بازار و خدمات با کارشناسان اِکس پلیمر با شماره
            ۰۹۱۲۱۰۰۲۱۸۶ تماس بگیرید و یا فرم زیر را پر کنید.
          </div>
          <div class="inputs">
            <div class="input">
              <input v-model="name" placeholder="نام و نام خانوادگی" />
              <input v-model="mobileNumber" placeholder="تلفن همراه" />
            </div>
            <button @click="sendEmail">ثبت</button>
          </div>
        </div>
      </div>
    </div>
    <div class="patterns">
      <img src="/img/supportPattern.webp" alt="background pattern" />
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref } from "vue";
const mobileNumber = ref("");
const name = ref("");
const sendEmail = async () => {
  const config = {
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    service_id: `${process.env.VUE_APP_SERVICE_EMAIL_ID}`,
    template_id: `${process.env.VUE_APP_TEMPLATE_EMAIL_ID}`,
    user_id: `${process.env.VUE_APP_USER_EMAIL_ID}`,
    template_params: {
      message: `شماره تماس : ${mobileNumber.value} نام و نام خانوادگی : ${name.value}`,
    },
  };
  // console.log(process.env);
  try {
    await axios.post(
      `https://api.emailjs.com/api/v1.0/email/send`,
      sendData,
      config
    );
  } catch (err) {
    console.log(err);
  }
};
</script>
<style lang="scss" scoped>
.support-container {
  padding-top: 100px;
  width: 100%;
  background-color: white;
  z-index: 10;
  .patterns {
    &:first-child {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .section {
    height: 600px;
    background-color: #edf3fc;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 40px;
    .image {
      margin-bottom: 25px;
      img {
        /* width: 350px; */
        height: 390px;
      }
    }
  }
  .paragraph {
    position: relative;
    z-index: 2;
    width: 726px;
    img {
      position: absolute;
      width: 766px;
      height: 250px;
      top: 30px;
      left: -20px;
      z-index: -1;
    }
  }
  .text {
    background-color: #0f2851;
    color: white;
    border-radius: 18px;
    height: 216px;
    padding: 15px;
    display: grid;

    .details {
      font-size: 14px;
      margin-top: -15px;
    }
    .header {
      font-size: 28px;
      .bold {
        color: #2068e1;
      }
    }
    .inputs {
      align-self: flex-end;
      height: 50px;
      padding: 5px;
      background-color: #2068e1;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      button {
        width: 138px;
        border: none;
        border-radius: 8px;
        color: white;
        background-color: #0f2851;
        &:hover {
          color: #2068e1;
          transform: scale(0.9);
          transition: 0.3s;
        }
      }
      input {
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0 16px;
        color: white;
        &:last-child {
          border-right: 1px solid #0f2851;
        }
        &::placeholder {
          color: #b5cef3;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .support-container {
    width: 100%;

    .section {
      height: 500px;
      gap: 40px;
      .image {
        margin-left: 5%;
        img {
          /* width: 350px; */
          height: 340px;
        }
      }
    }
    .paragraph {
      margin-right: 5%;
      width: 526px;
      img {
        width: 566px;
        height: 240px;
      }
    }
    .text {
      .details {
        font-size: 13px;
      }
      .header {
        font-size: 26px;
      }
      .inputs {
        align-self: flex-end;
        height: 45px;
        padding: 5px;

        button {
          width: 125px;
        }
        input {
          width: 45%;
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .support-container {
    width: 100%;
    .section {
      height: 400px;
      gap: 40px;
      .image {
        margin-left: 2.5%;
        img {
          height: 300px;
        }
      }
    }
    .paragraph {
      margin-right: 2.5%;
      width: 400px;
      img {
        width: 460px;
        height: 230px;
      }
    }
    .text {
      .details {
        font-size: 11px;
      }
      .header {
        font-size: 24px;
      }
      .inputs {
        align-self: flex-end;
        height: 35px;
        padding: 3px;
        button {
          width: 100px;
        }
        input {
          width: 45%;
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .support-container {
    width: 100vw;
    overflow: hidden;
    .section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      gap: 0;
      .image {
        position: absolute;
        top: 10px;
        img {
          height: 270px;
          width: 270px;
        }
      }
      .paragraph {
        position: relative;
        z-index: 2;
        width: 85%;
        .text {
          height: auto;
          .header {
            font-size: 20px;
            text-align: center;
            margin-bottom: 1rem;
          }
          .details {
            margin: 0;
            margin-bottom: 1rem;
            font-size: 10px;
          }
          .inputs {
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            button {
              margin-top: 10px;
              width: 100%;
              height: 35px;
              font-size: 14px;
            }
            .input {
              display: flex;
              flex-direction: column;
              width: 100%;
              border-bottom: #0f2851 1px solid;
              input {
                height: 40px;
                width: 100%;
                font-size: 13px;
                &:last-child {
                  border: none;
                }
                border-bottom: #0f2851 1px solid;
              }
            }
          }
        }
        img {
          position: absolute;
          width: 105%;
          height: 270px;
          top: 30px;
          left: -15px;
          z-index: -1;
        }
      }
    }
  }
}
</style>
